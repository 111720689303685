<template>
  <perseu-card title="Detalhes do agendamento">
    <template slot="content">
      <v-container>
        <v-row>
          <v-text-field
            v-model.trim="appointment.therapist.name"
            label="Terapeuta"
            readonly
            disabled
          />
        </v-row>
        <v-row>
          <v-text-field
            v-model.trim="appointment.patient.name"
            label="Paciente"
            readonly
            disabled
          />
        </v-row>

        <v-row>
          <v-col cols="6">
            <div>
              <span class="font-weight-bold" style="padding-right: 5px;">
                Público:
              </span>
              {{ appointment.public | booleanToString }}
            </div>
            <div>
              <span class="font-weight-bold">Status: </span>
              {{ appointment.status | transformStatusAppointment }}
            </div>

            <div v-if="appointment.cancellationReason">
              <span class="font-weight-bold">Motivo de cancelamento: </span>
              {{ appointment.cancellationReason }}
            </div>

            <div v-if="appointment.cancellationSource">
              <span class="font-weight-bold">Fonte cancelamento: </span>
              {{
                appointment.cancellationSource
                  | transformCancellationSourceAppointment
              }}
            </div>
          </v-col>
          <v-col cols="6">
            <div>
              <span class="font-weight-bold">Criado em: </span>
              {{ appointment.createdAt | formatDateTime }}
            </div>

            <div>
              <span class="font-weight-bold">Atualizado em: </span>
              {{ appointment.updatedAt | formatDateTime }}
            </div>

            <div>
              <span class="font-weight-bold">Iniciado em: </span>
              {{ appointment.startAt | formatDateTime }}
            </div>

            <div>
              <span class="font-weight-bold">Finalizado em: </span>
              {{ appointment.endAt | formatDateTime }}
            </div>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </perseu-card>
</template>

<script>
export default {
  components: {},
  props: {
    appointment: {},
  },
  data: () => ({}),
  computed: {},
  methods: {},
};
</script>

<style></style>
